<template>
  <div class="d-flex justify-content-end">
    <v-spacer></v-spacer>
    <v-btn-toggle dense>
      <v-menu
        ref="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            :color="isCustomDatesSelected ? 'accent' : 'primary'"
            v-on="on"
            v-bind="attrs"
          >
            {{ buttonText }}
          </v-btn>
        </template>
        <v-date-picker
          v-model="datesArr"
          range
          no-title
          first-day-of-week="1"
          @change="selectCustomDatesRange"
        />
      </v-menu>
      <v-btn text color="primary" @click="selectDay">Сегодня</v-btn>
      <v-btn text color="primary" @click="selectWeek">Эта неделя</v-btn>
      <v-btn text color="primary" @click="selectMonth">Этот месяц</v-btn>
      <v-btn text color="primary" @click="selectYear">Этот год</v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import moment from "moment";

const DATE_REGEXP = /^\d{4}-\d{2}-\d{2}$/;
const DATE_FORMAT = "YYYY-MM-DD";

function datesArrIsValid(datesArr) {
  return (
    Array.isArray(datesArr) &&
    [1, 2].includes(datesArr.length) &&
    datesArr.every((date) => date.match(DATE_REGEXP))
  );
}

export default {
  emits: ["setDates"],
  props: {
    dates: {
      type: Array,
      required: true,
      validate: datesArrIsValid,
    },
  },
  mounted() {
    this.setDatesLocally();
  },
  data() {
    return {
      isCustomDatesSelected: null,
      datesArr: null,
    };
  },
  methods: {
    setDatesLocally() {
      if (!datesArrIsValid(this.dates)) {
        throw new Error("Dates array in props is invalid");
      }
      this.datesArr = this.dates;
      this.isCustomDatesSelected = true;
    },
    selectDay() {
      const now = moment();
      this.datesArr = [now.format(DATE_FORMAT)];
      this.isCustomDatesSelected = false;
      this.updateDates();
    },
    selectWeek() {
      const now = moment();
      const startOfWeek = now.clone().startOf("isoWeek");
      const endOfWeek = now.clone().endOf("isoWeek");
      this.datesArr = [
        startOfWeek.format(DATE_FORMAT),
        endOfWeek.format(DATE_FORMAT),
      ];
      this.isCustomDatesSelected = false;
      this.updateDates();
    },
    selectMonth() {
      const now = moment();
      const startOfMonth = now.clone().startOf("month");
      const endOfMonth = now.clone().endOf("month");
      this.datesArr = [
        startOfMonth.format(DATE_FORMAT),
        endOfMonth.format(DATE_FORMAT),
      ];
      this.isCustomDatesSelected = false;
      this.updateDates();
    },
    selectYear() {
      const now = moment();
      const startOfYear = now.clone().startOf("year");
      const endOfYear = now.clone().endOf("year");
      this.datesArr = [
        startOfYear.format(DATE_FORMAT),
        endOfYear.format(DATE_FORMAT),
      ];
      this.isCustomDatesSelected = false;
      this.updateDates();
    },
    selectCustomDatesRange(dates) {
      this.datesArr =
        dates[0] === dates[1]
          ? [dates[0]]
          : dates.sort((a, b) => (a > b ? 1 : -1));
      this.isCustomDatesSelected = true;
      this.updateDates();
    },
    updateDates() {
      this.$emit("setDates", this.datesArr);
    },
  },
  computed: {
    buttonText() {
      return this.datesArr?.join(" ~ ");
    },
  },
};
</script>
