<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="lessonsData"
      :server-items-length="totalItems"
      :loading="isDataLoading"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
      :items-per-page="optionsTable.itemsPerPage"
      :options.sync="optionsTable"
    >
      <template v-slot:item.status="{ item }">
        <v-chip :color="getBadgeColorByLessonStatus(item.status)" small>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.participants="{ item }">
        {{ item.participants.length }}
      </template>
      <template v-slot:item.timestamp="{ item }">
        {{ formatDate(item.timestamp) }}
      </template>
      <template v-slot:item.connections_data="{ item }">
        <v-btn
          text
          color="pink"
          class="ms-5"
          @click="showLessonDetails(item.id)"
          >подробнее</v-btn
        >
      </template>
    </v-data-table>
    <v-dialog
      v-if="isShowLessonDetailsModal && lessonDetails"
      v-model="isShowLessonDetailsModal"
      max-width="1400px"
    >
      <v-card>
        <v-card-title class="text-h5">Детали урока </v-card-title>
        <v-card-text>
          <table>
            <tbody>
              <tr>
                <td><b>id урока</b></td>
                <td class="ps-4">{{ lessonDetails.id }}</td>
              </tr>
              <tr>
                <td><b>uuid урока</b></td>
                <td class="ps-4">{{ lessonDetails.uuid }}</td>
              </tr>
              <tr v-if="lessonDetails.course_id">
                <td><b>id курса</b></td>
                <td class="ps-4">{{ lessonDetails.course_id }}</td>
              </tr>
              <tr>
                <td><b>предмет</b></td>
                <td class="ps-4">{{ lessonDetails.subject_name }}</td>
              </tr>
              <tr v-if="lessonDetails.lesson_title">
                <td><b>название урока</b></td>
                <td class="ps-4">{{ lessonDetails.lesson_title }}</td>
              </tr>
              <tr v-if="lessonDetails.description">
                <td><b>описание урока</b></td>
                <td class="ps-4">{{ lessonDetails.description }}</td>
              </tr>
              <tr>
                <td><b>дата начала</b></td>
                <td class="ps-4">{{ formatDate(lessonDetails.timestamp) }}</td>
              </tr>
              <tr>
                <td style="white-space: nowrap"><b>длительность урока</b></td>
                <td class="ps-4">{{ lessonDetails.lesson_duration }} мин</td>
              </tr>
              <tr>
                <td><b>текущий статус</b></td>
                <td class="ps-4">
                  <v-chip
                    :color="getBadgeColorByLessonStatus(lessonDetails.status)"
                    small
                  >
                    {{ lessonDetails.status }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-title class="text-h5">
          Участники урока ({{ lessonDetails.participants.length }})
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Пользователь</th>
                <th class="text-left">id</th>
                <th class="text-left">Роль</th>
                <th class="text-left">Текущий статус</th>
                <th class="text-left">Онлайн (сек)</th>
                <th class="text-left">Лог онлайна</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="participant of lessonDetails.participants"
                :key="participant.id"
              >
                <td>
                  {{ participant.name }}
                </td>
                <td>
                  {{ participant.id }}
                </td>
                <td>
                  {{ participant.role === "tutor" ? "репетитор" : "студент" }}
                </td>
                <td>
                  <v-chip
                    :color="
                      getBadgeColorByParticipantStatus(participant.status)
                    "
                    small
                    >{{ participant.status }}</v-chip
                  >
                </td>
                <td>{{ participant.total_online_seconds }}</td>
                <td>
                  <table v-if="participant.connection_log.length">
                    <thead>
                      <tr>
                        <th class="pa-1">Подключение</th>
                        <th class="pa-1">Отключение</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          connectionLog, index
                        ) of participant.connection_log"
                        :key="index"
                      >
                        <td class="px-2">
                          {{ formatDate(connectionLog.connected_at) }}
                        </td>
                        <td class="px-2">
                          {{ formatDate(connectionLog.disconnected_at) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/../api";
import moment from "moment";

export default {
  props: {
    dates: {
      type: Array,
      required: true,
    },
    lessonType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDataLoading: false,
      lessonsData: [],
      totalItems: 0,
      optionsTable: {
        page: 1,
        itemsPerPage: 10,
      },

      lessonDetails: null,
      isShowLessonDetailsModal: false,

      headers: [
        { text: "Статус", value: "status", sortable: false },
        {
          text: "id урока",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Предмет", value: "subject_name", sortable: false },
        {
          text: "Дата начала",
          align: "start",
          value: "timestamp",
          sortable: false,
        },
        {
          text: "Длительность урока (мин)",
          value: "lesson_duration",
          sortable: false,
        },
        {
          text: "Кол-во участников урока",
          value: "participants",
          align: "start",
          sortable: false,
        },
        {
          text: "Данные подключений",
          value: "connections_data",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    dates: {
      handler: async function (val, oldVal) {
        if (val.toString() !== oldVal?.toString()) {
          await this.loadData();
        }
      },
      immediate: true,
      deep: true,
    },
    optionsTable: async function (val, oldVal) {
      if (val !== oldVal) {
        await this.loadData();
      }
    },
  },
  methods: {
    async loadData() {
      try {
        this.isDataLoading = true;
        const { page, itemsPerPage } = this.optionsTable;
        const props = {
          page,
          itemsPerPage,
          date_from: this.dates[0],
          date_to: this.dates[1] || this.dates[0],
        };
        const dataFetcher = {
          "individual-lessons": api.lessons.individualLessonsStatistics,
          "group-lessons": api.lessons.groupLessonsStatistics,
          "course-lessons": api.lessons.courseLessonsStatistics,
        };
        const {
          data: { items, total },
        } = await dataFetcher[this.lessonType](props);
        this.lessonsData = items;
        this.totalItems = total;
      } catch (err) {
        console.error(err);
      } finally {
        this.isDataLoading = false;
      }
    },
    getBadgeColorByLessonStatus(status) {
      const colors = {
        new: "#dedede",
        canceled: "#d4c1ff",
        completed: "#aae57b",
        started: "#a4f0ff",
        failed: "#ffa5a5",
        failed_by_tutor: "#ffb580",
        failed_by_student: "#fde561",
        ending_process: "#dedede",
      };
      return colors[status] || "#dedede";
    },
    getBadgeColorByParticipantStatus(status) {
      const colors = {
        new: "#dedede",
        connected: "#a4f0ff",
        "not-connected": "#ffa5a5",
        "disconnected-during-event": "#fde561",
        "disconnected-after-complete": "#d4c1ff",
        "not-reconnect-on-time": "#ffb580",
      };
      return colors[status] || "#dedede";
    },
    async showLessonDetails(lessonId) {
      const dataFetcher = {
        "individual-lessons": api.lessons.singleIndividualLessonStatistics,
        "group-lessons": api.lessons.singleGroupLessonStatistics,
        "course-lessons": api.lessons.singleCourseLessonStatistics,
      };
      const { data } = await dataFetcher[this.lessonType](lessonId);
      this.lessonDetails = data;
      this.isShowLessonDetailsModal = true;
    },
    formatDate(date) {
      return date ? moment(date).format("YYYY-MM-DD HH:mm") : "---";
    },
  },
};
</script>
