var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.coursesData,"server-items-length":_vm.totalItems,"loading":_vm.isDataLoading,"footer-props":{ itemsPerPageOptions: [10, 15, 20, 50, 100] },"items-per-page":_vm.optionsTable.itemsPerPage,"options":_vm.optionsTable},on:{"update:options":function($event){_vm.optionsTable=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getBadgeColorByLessonStatus(item.status),"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.start_recruitment_timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.start_recruitment_timestamp))+" ")]}},{key:"item.end_recruitment_timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.end_recruitment_timestamp))+" ")]}},{key:"item.lessons_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.course_lessons.length)+" ")]}},{key:"item.course_details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ms-5",attrs:{"text":"","color":"pink"},on:{"click":function($event){return _vm.showCourseDetails(item.id)}}},[_vm._v("подробнее")])]}}])}),(_vm.isShowCourseDetailsModal && _vm.courseDetails)?_c('v-dialog',{attrs:{"max-width":"1400px"},model:{value:(_vm.isShowCourseDetailsModal),callback:function ($$v) {_vm.isShowCourseDetailsModal=$$v},expression:"isShowCourseDetailsModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Уроки курса")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Статус")]),_c('th',{staticClass:"text-left"},[_vm._v("id")]),_c('th',{staticClass:"text-left"},[_vm._v("Тема урока")]),_c('th',{staticClass:"text-left"},[_vm._v("Дата начала урока")])])]),_c('tbody',_vm._l((_vm.courseDetails.course_lessons),function(lesson){return _c('tr',{key:lesson.id},[_c('td',[_c('v-chip',{attrs:{"color":_vm.getBadgeColorByLessonStatus(lesson.status),"small":""}},[_vm._v(" "+_vm._s(lesson.status)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(lesson.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(lesson.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(lesson.timestamp))+" ")])])}),0)]},proxy:true}],null,false,3043715854)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }