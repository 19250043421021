<template>
  <div class="pt-5">
    <DateSelector :dates="dates" @setDates="setDates" />
    <LessonsTabs :dates="dates" class="mt-10" />
  </div>
</template>

<script>
import moment from "moment";
import LessonsTabs from "@/components/Lessons/LessonsTabs.vue";
import DateSelector from "@/components/DateSelector.vue";

export default {
  components: {
    DateSelector,
    LessonsTabs,
  },
  data() {
    return {
      dates: [],
    };
  },
  created() {
    const startDate = moment().format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    this.dates = [startDate, endDate];
  },
  methods: {
    setDates(dates) {
      console.log(dates);
      this.dates = dates;
    },
  },
};
</script>

<style scoped></style>
